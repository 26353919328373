// Sidebar.js

import React, { useState, useEffect } from 'react';
import './Sidebar.css';

const Sidebar = (props) => {
    const[city, setCity] = useState(props ? props.city : '');
    const[state, setState] = useState(props ? props.state : '')
    useEffect(() => {
        setCity(props.city);
        setState(props.state);
    }, [props.city, props.state])
    const setButter = (event) => {
        props.setState(state);
        props.setCity(city);
    }
    const handleLocationChange = (event) => {
        props.setL(parseInt(event.target.value));
    }
    const handlePriceChange = (event) => {
        props.setP(parseInt(event.target.value));
    }
    const [categories, setCategories] = useState([
        'all categories',
        'electronics',
        'clothing',
        'cars',
        'jewelry',
        'home decor',
        'sports equipment',
        'books',
        'beauty products',
        'furniture',
        'kitchen appliances',
        'toys',
        'pet supplies',
        'tools',
        'outdoor gear',
        'office supplies',
        'healthcare products',
        'musical instruments',
        'craft supplies',
        'party supplies',
        'baby products',
        'art supplies',
        'gardening supplies',
        'travel accessories',
        'fitness equipment',
        'video games',
        'cell phone accessories',
        'hobby items',
        'candles',
        'stationery',
        'board games',
        'other'
    ]);
    const handleSChange = (event) => {
        setState(event.target.value);
    }
    const handleCChange = (event) => {
        setCity(event.target.value);
    }
    return (
    <aside className={`sidebar ${props.isSidebarActive ? 'active' : ''}`}>
      <div className="toolbar">
        <h2>Categories</h2>
        <hr/>
        <ul className={'category-list'}>
          {categories.map(category => (
            <li key={category} onClick={() => props.setSelectedCategory(category)}>
              {category}
            </li>
          ))}
        </ul>
        <h2>Filters</h2>
        <hr className="fil"/>
          <div className={"geoDiv"}>
              <input placeholder={"City"} value={city} onChange={handleCChange}/>
              <input placeholder={"State"} value={state} onChange={handleSChange}/>
              <button onClick={setButter}>Set Location</button>
              <br/>
          </div>
          <hr className="fil"/>
        <div className="filtersContainer">
            <label htmlFor="location">Location:</label>
            <select name="location" onChange={handleLocationChange}>
                <option value={-1}>any</option>
                <option value={25}>Within 25mi</option>
                <option value={50}>Within 50mi</option>
                <option value={100}>Within 100mi</option>
            </select>
            <label htmlFor="price">Price:</label>
            <select name="price" onChange={handlePriceChange}>
                <option value={-1}>any</option>
                <option value={25}>$0-$25</option>
                <option value={50}>$25-$50</option>
                <option value={100}>$50-$100</option>
                <option value={101}>$100+</option>
            </select>
        </div>
      </div>
    </aside>
  );
}

export default Sidebar;
