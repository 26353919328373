// ErrorPage.js

import React from 'react';
import './ErrorPage.css';

import Header from "../Header/header";
import Footer from "../Footer/footer";

function ErrorPage(props) {
  return (
    <div>
        <Header {...props}/>
        <div className='error-container'>
        <h1>404 Not Found</h1>
        <p>The page you requested does not exist.</p>
        {/* Add a link to navigate back to the home page */}
        <a href="/">Go to Home Page</a>
        </div>
    </div>
  );
}

export default ErrorPage;
