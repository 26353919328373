import React, {useEffect, useRef, useState} from 'react';
import './AddMarketListing.css';
import Header from "../Header/header";
import Footer from "../Footer/footer";
import { useNavigate } from "react-router-dom";
import MarketHeader from "./MarketHeader";
import {use} from "bcrypt/promises";
import leftArrow from '../assets/images/left-arrow.png'
import rightArrow from '../assets/images/right-arrow.png'

const AddMarketListing = (props) => {
    const navigate = useNavigate();
    const [currentSlide, setTheSlide] = useState(0);
    const [invalidInput, setInvalidInput] = useState('');
    const [erroring, setTheError] = useState(false);
    const [categories, setCategories] = useState([
        'art supplies',
        'baby products',
        'beauty products',
        'board games',
        'books',
        'candles',
        'cars',
        'cell phone accessories',
        'clothing',
        'craft supplies',
        'electronics',
        'fitness equipment',
        'furniture',
        'gardening supplies',
        'healthcare products',
        'hobby items',
        'home decor',
        'jewelry',
        'kitchen appliances',
        'musical instruments',
        'office supplies',
        'outdoor gear',
        'party supplies',
        'pet supplies',
        'sports equipment',
        'stationery',
        'tools',
        'toys',
        'travel accessories',
        'video games',
        'other'
    ]);
    const [formData, setFormData] = useState({
        title: '',
        price: '',
        description: '',
        images: [],
        sessionId: props.sessionId,
        category: '',
        city: props.city,
        state: props.state
    });
    const [fade, setFade] = useState(false);

    const handleChange = e => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value,
        }));
    };
    const [images, setImages] = useState([]);
    const [hasNoImages, setHasNoImages] = useState(false);
    const handleImageUpload = (event) => {
        const newImages = Array.from(event.target.files);
        console.log('New Images: ', newImages)
        setImages(newImages);
        setHasNoImages(false);
    };

    const handleSubmit = async e => {
        e.preventDefault();

        if (isNaN(parseFloat(formData.price)) || parseFloat(formData.price) <= 0) {
            setInvalidInput('price');
            return;
        }
        if (!/[a-zA-Z]/.test(formData.title)) {
            setInvalidInput('title');
            return;
        }

        try {
            const uploadedImageIds = [];

            for (const image of images) {
                const formDataImage = new FormData();
                formDataImage.append('image', image);
                formDataImage.append('sessionId', props.sessionId);
                formDataImage.append('name', 'Some name');

                const response = await fetch(`${props.apiUrl}/api/images`, {
                    method: 'POST',
                    body: formDataImage
                });

                if (!response.ok) {
                    throw new Error('Failed to upload image');
                }

                const imageData = await response.json();
                console.log(imageData);
                uploadedImageIds.push(imageData.id);
                console.log(uploadedImageIds);
            }
            const formDataWithImages = {
                ...formData,
                images: uploadedImageIds
            };

            if (uploadedImageIds.length === 0) {
                console.log('NO IMAGES, CANT SUBMIT');
                setHasNoImages(true);
                return;
            }

            setFormData(formDataWithImages); 
        } catch (error) {
            console.error('Error:', error);
            setTheError(true);
        }
    };

    useEffect(() => {
        if (formData.images.length > 0) {
            const submitData = async () => {
                try {
                    await props.handleAPI('listings', formData, 'POST', { sessionId: props.sessionId });
                    props.setAI(false);
                    props.setRK(prevKey => prevKey + 1);
                    navigate('/Marketplace');
                } catch (error) {
                    console.error('Error:', error);
                }
            };

            submitData();
        }
    }, [formData, navigate, props]);

    const removeNewImage = index => {
        console.log(images.length)
        setImages(prevImages => {
            const updatedImages = [...prevImages];
            updatedImages.splice(index, 1);
            return updatedImages;
        });
        console.log(images.length)
        setFormData({ ...formData });
    }
    const slideRef = useRef(null);
    const slideChange = (index) => {
        setTheSlide(index);
    };
    useEffect(() => {
        if (slideRef.current) {
            slideRef.current.scrollLeft = currentSlide * slideRef.current.offsetWidth;
        }
    }, [currentSlide]);
    const handleRight = () => {
        if (images.length - 1 > currentSlide) setTheSlide(currentSlide + 1);
    };
    const handleLeft = () => {
        if (currentSlide > 0) setTheSlide(currentSlide - 1);
    };

    useEffect(() => {
        setFade(true);
    }, [props.isVisible]);

    return (
        <>
            <div className={`add-listing-container  ${fade ? 'active' : ''}`}>
                <div id='ALC-heading'>
                    <h2>Add New Market Listing</h2>
                </div>
                <form onSubmit={handleSubmit}>
                    <div id="form-group-wrap">
                        <div className="form-group">
                            <label>Title:</label>
                            <input type="text" name="title" value={formData.title} onChange={handleChange} required />
                            {invalidInput==='title' && <p className='err'>Please enter a valid title.</p>}
                        </div>
                        <div className="form-group">
                            <label>Price:</label>
                            <div className='price-container'>
                                <span className="dollar-sign">$</span>
                                <input type="text" name="price" id="price-input" value={formData.price} onChange={handleChange} required />
                            </div>
                            {invalidInput==='price' && <p className='err'>Please enter a valid price.</p>}
                        </div>
                        <div className="form-group">
                            <label>City:</label>
                            <textarea name="city" value={formData.city} onChange={handleChange} required />
                        </div>
                        <div className="form-group">
                            <label>State:</label>
                            <textarea name="state" value={formData.state} onChange={handleChange} required />
                        </div>
                        <div className="form-group">
                            <label>Description:</label>
                            <textarea name="description" value={formData.description} onChange={handleChange} required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="category">Category:</label>
                            <select name="category" value={formData.category} onChange={handleChange} required>
                                <option value="">Select a category</option>
                                {categories.map(category => (
                                    <option key={category} value={category}>{category}</option>
                                ))}
                            </select>
                        </div>
                        <div className="form-group">
                            <label>Images:</label>
                            <div className='choose-img'>
                                <button type="button" id='filebtn' htmlFor="fileinput" onClick={() => document.getElementById('fileinput').click()}>Choose images to upload</button>
                                <input type="file" id='fileinput' accept="image/*" onChange={handleImageUpload} multiple />
                                <p id='numimgs'>{`${images.length} image(s) selected`}</p>
                            </div>
                            {hasNoImages && <p className='err'>Listing must have at least one image.</p>}
                        </div>
                    </div>
                    
                    
                    <div className={'slides'} ref={slideRef}>
                        {images && images.length > 0 && (
                                images.map((image, index) => (
                                    <div className='img-and-button-box'>
                                        <img key={index} src={URL.createObjectURL(image)} width={'100%'} alt={`Preview ${index + 1}`} />
                                        <button className='remove' type='button' onClick={() => removeNewImage(index)}>Remove</button>
                                    </div>
                                ))
                        )}
                    </div>
                    { images && images.length > 1 && <div>
                        <img alt="left" onClick={handleLeft} id={"left_arrow"} src={leftArrow}/>
                        <img alt= "right" onClick={handleRight} id={"right_arrow"} src={rightArrow}/>
                        <div className="dots">
                            {images && images.length > 0 && (
                                images.map((_, index) => (
                                    <span onClick={() => slideChange(index)} key={index} className={`dot ${index === currentSlide ? 'active' : ''}`}></span>
                                ))
                            )}
                        </div>
                    </div>}
                    <div className={'bgroup'}>
                        <button type="submit">Add Listing</button>
                        <button type="button" onClick={() => props.setAI(false)}>Cancel</button>
                    </div>
                    {erroring && <p align={'center'}>One of the images uploaded is the wrong file type.</p>}
                </form>
            </div>
        </>
    );
};

export default AddMarketListing;
