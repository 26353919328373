import React, { useState } from "react";
import './SearchBar.css';

const SearchBar = (props) => {
    const [filteredItems, setFilteredItems] = useState([]);
    const cats = ['cars', 'watches', 'books', 'phones', 'computers', 'clothing', 'shoes', 'appliances', 'furniture', 'electronics'];
    const atts = ['for sale', 'for rent', 'for 50 dollars', 'in good condition', 'brand new', 'used', 'with warranty', 'limited edition', 'with accessories', 'free shipping'];
    const generateItemList = () => {
        const itemList = [];

        for (const category of cats) {
            for (const attribute of atts) {
                itemList.push({
                    id: itemList.length + 1,
                    name: `${category} ${attribute}`
                });
            }
        }

        return itemList;
    };
    const itemList = generateItemList();


    const handleInputChange = (event) => {
        const searchTerm = event.target.value;
        props.setSearchTerm(searchTerm);
        const filtered = itemList.filter(item =>
            item.name.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredItems(filtered);
    };

    const handleItemClick = (item) => {
        props.setSearchTerm(item);
        setFilteredItems([]);
    };

    const handleButtonClick = () => {
        console.log(props.SearchCheck);
        props.setSearchCheck(prev => prev+1);
        console.log(props.searchCheck);
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleButtonClick();
        }
    }

    return (
        <div className={'searchbar_wrap'}>
            <div className={'left'}>
                <input id='barbox'
                    placeholder={'Search'}
                    value={props.searchTerm}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                />
                {props.searchTerm && filteredItems.length > 0 && (
                    <div className="dropdown">
                        <ul className="dropdown-content">
                            <li> {/* blank */} </li>
                            {filteredItems.map(item => (
                                <li key={item.id} onClick={() => handleItemClick(item.name)}>
                                    {item.name}
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>
            <div className={'right'}>
                <button onClick={handleButtonClick}></button>
            </div>
        </div>
    );
};

export default SearchBar;
