import './ViewUser.css';
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import ItemCard from "../Marketplace/ItemCard";
import Header from "../Header/header";
import Footer from "../Footer/footer";
import placeholder from '../assets/images/default-avatar.png'

const ViewUser = (props) => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [peefpee, setPFP] = useState(null);
    const [user, setUser] = useState({});
    const [listings, setListings] = useState([]);

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const res = await fetch(`${props.apiUrl}/api/auth/${id}`, {
                    method: 'GET'
                });
                const j = await res.json();
                if (!res.ok) {
                    console.log('Failed to fetch user:', j);
                    return;
                }
                setUser(j);
                if (j.id === '' || j.id === undefined) {
                    setPFP(null);
                } else {
                    setPFP(`${props.apiUrl}/api/images/${j.id}`);
                }
            } catch (error) {
                console.error('Error fetching user:', error);
            }
        };

        fetchUser();
        localStorage.setItem('returnRoute', `/view/${id}`);
    }, [id]);

    useEffect(() => {
        console.log(props)
        const fetchListings = async () => {
            try {
                const listingPromises = user.listings.map(listingId =>
                    fetch(`${props.apiUrl}/api/listings/${listingId}`)
                        .then(res => res.json())
                );
                const listingsData = await Promise.all(listingPromises);
                setListings(listingsData);
            } catch (error) {
                console.error('Error fetching listings:', error);
            }
        };

        if (user.listings) {
            fetchListings();
        }
        console.log(user);
    }, [user.listings]);


    const dateAccountCreated = new Date(user.dateCreated);
    const currentDate = new Date();
    const timeDifference = currentDate - dateAccountCreated;

    const daysSinceAccountCreation = Math.floor(timeDifference/ (1000*60*60*24));
    const hoursSinceAccountCreation = Math.floor(timeDifference/ (1000*60*60));
    const minutesSinceAccountCreation = Math.floor(timeDifference/ (1000*60));
    const secondsSinceAccountCreation = Math.floor(timeDifference/(1000));

    let accountCreationDateDisplay = '';
    if (daysSinceAccountCreation < 1) {
        if (hoursSinceAccountCreation < 1) {
            if (minutesSinceAccountCreation < 1) {
                if (secondsSinceAccountCreation===1) {
                    accountCreationDateDisplay = `Joined 1 second ago`
                }
                else {
                    accountCreationDateDisplay = `Joined ${secondsSinceAccountCreation} seconds ago`
                }
            } else if (minutesSinceAccountCreation === 1) {
                accountCreationDateDisplay = `Joined 1 minute ago`
            } else {
                accountCreationDateDisplay = `Joined ${minutesSinceAccountCreation} minutes ago`
            }
        } else if (hoursSinceAccountCreation===1) {
            accountCreationDateDisplay = `Joined 1 hour ago`
        } else {
            accountCreationDateDisplay = `Joined ${hoursSinceAccountCreation} hours ago`
        }
    } else if (daysSinceAccountCreation===1) {
        accountCreationDateDisplay = `Joined 1 day ago`
    } else {
        accountCreationDateDisplay = `Joined ${daysSinceAccountCreation} days ago`
    }

    return (
        <>
            <Header {...props}/>
            <div className={'view_user_wrap'}>
                {id === props.ownerId && <button id='edit_btn' onClick={() => navigate('/EditProfile')}>Edit</button>}
                <div id='top_wrapper'>
                    <div className='full_row'> 
                        <div className='user_info_box'>
                            <img alt='profile_pic' src={peefpee ? peefpee : placeholder} id='p_f_p' /> 
                            <div id='user_info_column'>
                                <p id='username'>{user.user}</p>
                                {user.location?.city && user.location?.state ? <p>{user.location.city}, {user.location.state}</p> : <p>Location not set</p>}
                                <p>{accountCreationDateDisplay}</p>
                                {/*In the future we need to calculate the user's avg
                                star rating and use it to fill the stars.*/}
                                <p>{`⭐⭐⭐⭐⭐`}</p>
                            </div>
                        </div>
                        <div id='vertical_white_line'></div>
                        <div className='user_description_box'>
                            <h2>Description:</h2>
                            {user.description ? <p id='userdesc'>{user.description}</p> : <p id='userdesc'>Description not set.</p>}
                        </div>
                    </div>
                    <hr id='hrrr'/>
                </div>
                <div className='heading_box'>
                        <h2>Ratings:</h2>
                </div>
                <div id='ratings_box'>
                    {user.ratings && user.ratings.map(rating => (
                        <div>
                            <p>Posted by: {rating.poster}</p>
                            <p>Rating: {rating.rating}</p>
                            <p>Comment: {rating.comment}</p>
                        </div>
                    ))}
                </div>
                <div className='heading_box'>
                        <h2>User's listings:</h2>
                </div>
                <div id='user_listings_box'>
                    {listings && listings.map(item => (
                        <div id='viewuser_itemcard_container'>
                            <ItemCard {...item} apiUrl={props.apiUrl} key={item._id} />
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default ViewUser;
