import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState, useRef } from "react";
import {use} from "bcrypt/promises";
import Header from "../Header/header";
import Footer from "../Footer/footer";
import './VerifyPage.css'
import email_icon from '../assets/images/email_icon.png'

const VerifyPage = (props) => {
    const navigate = useNavigate();
    const {email} = useParams()
    const [msg, setM] = useState('Sending email...');
    const [stat, setStatus] = useState(false);
    const hasRun = useRef(false);
    const [mounted, setMounted] = useState(false);

    useEffect(() => {
        setMounted(true);
    }, []);

    const handleProcess = () => {
        setM('Sending email...')
        fetch(`${props.apiUrl}/api/auth/getVerified/${email}`).then(res => {
            if (res.ok) {
                setM(`We have sent a verification link to ${email}. Please check your spam/junk folder!`)
                setStatus(true);
                console.log('email sent');
            }
        })
    }

    if (!hasRun.current && mounted) {
        console.log('yooo');
        hasRun.current = true;
        if (email) {
            handleProcess()
        }
    }


    // useEffect(() => {
    //     if (email) {
    //         handleProcess()
    //     }
    // }, [email])
    
    const handleClick = () => {
        if (stat) {
            navigate('/Login');
        }
    }
    return (
        <>
            <Header {...props}/>
            <div className={'loggerin'}>
                <div className={'signlogbox'}>
                    <img  id='email' src={email_icon}></img>
                    <h1 id='ev-header'>Please verify your email</h1>
                    <p id='ev-msg'>{msg}</p>
                    {stat && <p id='ev-instructions'>Click on the link to complete the verification process.</p>}
                    {stat && <p id='spam'>You might need to check your spam folder.</p>}
                    {stat && <p id='scfte'>Still can't find the email?</p>}
                    <button id='resend' onClick={handleProcess}>Resend Email</button>
                    
                </div>
            </div>
        </>
    );
}
export default VerifyPage