import React, { useState, useEffect } from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import './ListingInfo.css'
import placeholder from '../assets/images/default-avatar.png'

const ListingInfo = (props) => {
    const { uriParams } = useParams();
    const [item, setItem] = useState({});
    const [loading, setLoading] = useState(true);
    const _id = uriParams ? JSON.parse(decodeURIComponent(uriParams)) : {};
    const [mapLink, setML] = useState('')
    const [preview, setPreview] = useState(null);
    const navigate = useNavigate();
    useEffect(() => {
        console.log(item);
        fetch(`${props.apiUrl}/api/auth/${item.owner}`, {
            method: 'GET'
        }).then(async res => {
            if (res.success) {
                console.log(res, 'failure');
            }
            const j = await res.json();
            // console.log('the j.id is: ', j.id)
            if (j.id === '' || j.id === undefined) {
                setPreview(null);
            } else {
                setPreview(`${props.apiUrl}/api/images/${j.id}`);
            }
            // console.log('debug preview: ', preview)
        })
    }, [item]);
    useEffect( () => {
         props.handleAPI(`listings/${_id}`, null, 'GET', {})
            .then(data => {
                const itemer = {
                    ...data ?? 'none',
                }
                setItem(itemer);
                // console.log('Joe', item);
                setLoading(false);
                if (itemer) {
                    setML(`https://www.google.com/maps/embed/v1/place?key=${props.mapsKey}&q=${encodeURIComponent(itemer.city)},${encodeURIComponent(itemer.state)}`);
                }
            });
    }, [props.refreshKey]);

    if (loading) {
        return <h1 id='loading'>Loading...</h1>
    }

    const datePosted = new Date(item.date);
    const currentDate = new Date();
    const timeDifference = currentDate - datePosted;

    const daysSincePost = Math.floor(timeDifference/ (1000*60*60*24));
    const hoursSincePost = Math.floor(timeDifference/ (1000*60*60));
    const minutesSincePost = Math.floor(timeDifference/ (1000*60));
    const secondsSincePost = Math.floor(timeDifference/(1000));

    let dateDisplay = '';
    if (daysSincePost < 1) {
        if (hoursSincePost < 1) {
            if (minutesSincePost < 1) {
                if (secondsSincePost===1) {
                    dateDisplay = `Posted 1 second ago`
                }
                else {
                    dateDisplay = `Posted ${secondsSincePost} seconds ago`
                }
            } else if (minutesSincePost === 1) {
                dateDisplay = `Posted 1 minute ago`
            } else {
                dateDisplay = `Posted ${minutesSincePost} minutes ago`
            }
        } else if (hoursSincePost===1) {
            dateDisplay = `Posted 1 hour ago`
        } else {
            dateDisplay = `Posted ${hoursSincePost} hours ago`
        }
    } else if (daysSincePost===1) {
        dateDisplay = `Posted 1 day ago`
    } else {
        dateDisplay = `Posted ${daysSincePost} days ago`
    }

    const locDisplay = `${item.city}, ${item.state}`
    const dateAndLocDisplay = `${dateDisplay ?? 'no date'} in ${locDisplay}`
    return (
        <div className="info_container">
            <h2>{item.title ?? ' '}</h2>
            <hr/>
            <p id="price">${item.price ?? 'free'}</p>
            <p id="date">{dateAndLocDisplay}</p>
            {mapLink !== undefined && <iframe id='mapembed'
                loading="lazy"
                allowfullscreen
                referrerpolicy="no-referrer-when-downgrade"
                src={mapLink}>
            </iframe>}

            {/* <p>{item && item.location ? (item.location.lat || '0') : '0'}, {item && item.location ? (item.location.long || '0') : '0'}</p> */}
            {/* <p>Category: {item.category ?? 'other'}</p> not sure we need to display this?*/}
            <div id="descbox">
                <p id="deschead">Description</p>
                <p id="desc">{item.description ?? 'none'}</p>
            </div>
        </div>
    );
}

export default ListingInfo;