import './App.css';
import About from './components/About/About';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Login from "./components/Login/Login";
import Signup from "./components/Signup/Signup";
import Marketplace from "./components/Marketplace/Marketplace";
import {useEffect, useState} from "react";
import MarketListing from "./components/Marketplace/MarketListing";
import ErrorPage from './components/ErrorPage/ErrorPage';
import * as Geolib from "geolib";
import ResetPassword from './components/ResetPassword/ResetPassword';
import Verify from "./components/Verify/Verify";
import VerifyPage from "./components/VerifyPage/VerifyPage";
import EditProfile from "./components/EditProfile/EditProfile";
import ViewUser from "./components/ViewUser/ViewUser";
import MobileNotAvailable from "./components/Mobile/MobileNotAvailable";

function App() {
    const apiUrl = process.env.REACT_APP_API_URL;
    const mapsKey = process.env.REACT_APP_GOOGLE_API_KEY;
    const [login_state, login_sign] = useState(false);
    const [sessionId, setSID] = useState('');
    const [currentLocation, setCL] = useState({});
    const [ownerId, setOwnerId] = useState('');
    const [ownerUsername, setUsername] = useState('');
    const [ownerEmail, setOwnerEmail] = useState('');
    const [ownerDescription, setDescription] = useState('')
    const [ownerState, setOwnerState] = useState('');
    const [ownerCity, setOwnerCity] = useState('');
    useEffect(() => {
        document.title = 'JMark';
        const storedSessionId = localStorage.getItem('sessionId');
        const storedOwnerId = localStorage.getItem('ownerId');
        const storedUser = localStorage.getItem('uName');
        const storedEmail = localStorage.getItem('ownerEmail');
        const storedD = localStorage.getItem('ownerD');
        const storedC = localStorage.getItem('ownerC');
        const storedS = localStorage.getItem('ownerS');
        if (storedSessionId) {
            setSID(storedSessionId);
            setOwnerId(storedOwnerId);
            setUsername(storedUser);
            setOwnerEmail(storedEmail);
            setDescription(storedD);
            setOwnerState(storedS);
            setOwnerCity(storedC);
            // console.log(JSON.stringify(storedSessionId));
            login_sign(true);
        }
    }, []);
    const handleLogin = (sid) => {
        if (!login_state) {
            login_sign(true);
            setSID(sid.sessionId);
            setOwnerId(sid.userId);
            setUsername(sid.username);
            setOwnerEmail(sid.email);
            setDescription(sid.description);
            setOwnerState(sid.location.state);
            setOwnerCity(sid.location.city);
            localStorage.setItem('sessionId', sid.sessionId);
            localStorage.setItem('uName', sid.username);
            localStorage.setItem('ownerId', sid.userId);
            localStorage.setItem('ownerEmail', sid.email);
            localStorage.setItem('ownerD', sid.description);
            localStorage.setItem('ownerS', sid.location.state);
            localStorage.setItem('ownerC', sid.location.city);
        }
    };
    const handleLogout = async () => {
        try {
            await handleAPI('auth/logout', { sessionId: sessionId });
            login_sign(false);
            setSID('');
            localStorage.removeItem('sessionId');
            localStorage.removeItem('uName');
        } catch (error) {
            console.error('Logout failed:', error);
        }
    };

    const handleAPI = (extension, props, method = 'POST', req = {}) => {
        let url = `${apiUrl}/api/` + extension;
        // Determine if method is GET or DELETE
        const isGetOrDelete = ['GET', 'DELETE'].includes(method.toUpperCase());

        // Add req parameters for GET and DELETE requests
        if (isGetOrDelete) {
            const params = new URLSearchParams(req);
            if (params.toString() !== '') {
                url += '?' + params.toString();
            }
        }

        // Construct requestOptions
        const requestOptions = {
            method: method.toUpperCase(),
            headers: {
                'Content-Type': 'application/json'
            }
        };

        // Add body for POST and PUT requests
        if (!isGetOrDelete) {
            requestOptions.body = JSON.stringify(props);
        }
        // console.log(props);

        // Only uncomment for debugging
        // console.log(requestOptions); 

        return fetch(url, requestOptions)
            .then(response => {
                if (!response.ok) {
                    console.log('Bad response', response);
                   // throw new Error('Network response was not ok');
                    return {};
                }
                return response.json();
            })
            .catch(error => {
                console.error('Error:', error);
                //throw error;
                return {};
            });
    };



    const isInDistance = (loc1, loc2, radius) => {
        const distance = Geolib.getDistance(loc1, loc2);
        const distanceInMiles = Geolib.convertDistance(distance, 'mi');
        // console.log("loc1:" , loc1)
        // console.log("loc2:", loc2)
        // console.log("dist in miles:", distanceInMiles)
        // console.log("radius:" , radius)
        return distanceInMiles <= radius;
    }
    const getCurrentLocation = () => {
        return new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(position => {
                resolve({
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude
                });
            }, reject);
        });
    };

    const getGeoData = async (lat, long) => {
        return await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat}%2C${long}&language=en&key=`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
        })
            .then(response => {
                if (!response.ok) {
                    console.log('Bad response', response)
                } else {
                    return response.json();
                }
                return {};
            })
            .catch(error => {
                console.error('Error:', error);
        });
    }
    const getWhereAbouts = async (setWhereResults) => {
        try {
            const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${currentLocation.latitude},${currentLocation.longitude}&location_type=ROOFTOP&result_type=street_address&key=${process.env.REACT_APP_GOOGLE_API_KEY}`);
            if (!response.ok) {
                console.log('Location not set')
            }
            const data = await response.json();
            console.log('Fetched data:', data);
            setWhereResults(data.plus_code.compound_code);
        } catch (error) {
            console.error('Error fetching data:', error);
            return {};
        }
    }

    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const checkScreenSize = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        window.addEventListener('resize', checkScreenSize);
        checkScreenSize();
        return () => window.removeEventListener('resize', checkScreenSize);
    }, []);


    const propers = {
        login_state,
        login_sign,
        setSID,
        sessionId,
        handleAPI,
        handleLogin,
        handleLogout,
        isInDistance,
        currentLocation,
        setCL,
        getCurrentLocation,
        getGeoData,
        getWhereAbouts,
        ownerId,
        ownerUsername,
        ownerEmail,
        ownerDescription,
        ownerCity,
        ownerState,
        apiUrl,
        mapsKey
    }
    return (
        <>
            {!isMobile &&
                (<Router>
                    <Routes>
                        <Route path="/Login" element={<Login {...propers}/>}/>
                        <Route path="/SignUp" element={<Signup {...propers}/>}/>
                        <Route path="/About" element={<About {...propers}/>}/>
                        <Route path="/Solutions" element={<About {...propers}/>}/>
                        <Route path="/" element={<Marketplace {...propers}/>}/>
                        <Route path="/Marketplace" element={<Marketplace {...propers}/>}/>
                        <Route exact path="/listings/:uriParams/" element={<MarketListing {...propers}/>} />
                        <Route path="*" element={<ErrorPage {...propers} />} />
                        <Route path="/reset/:token" element={<ResetPassword {...propers} />} />
                        <Route path="/EditProfile" element={<EditProfile {...propers} setUsername={setUsername}
                                                             setEmail={setOwnerEmail}
                                                             setDescription={setDescription}
                                                             setOwnerCity={setOwnerCity}
                                                             setOwnerState={setOwnerState}/>} />
                        <Route path="/verify/:token" element={<Verify {...propers} />} />
                        <Route path="/verifyit/:email" element={<VerifyPage {...propers} />} />
                        <Route path="/view/:id" element={<ViewUser {...propers} />} />
                    </Routes>
                </Router>)}
            {isMobile && (<MobileNotAvailable/>)}
        </>
  );
}

export default App;
