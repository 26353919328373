// ItemCard.js

import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import './ItemCard.css';
import Slides from "../Slides/slides";
import loadin from '../assets/images/loadin.gif'

const ItemCard = (props) => {
    const [theImages, setImages] = useState([loadin]);
    const uriParams = encodeURIComponent(JSON.stringify(props._id));
    // console.log(uriParams);
    return (
        <Link to={`/listings/${uriParams}`} onClick={() => localStorage.setItem('returnRoute',`/listings/${uriParams}`)} className="item-card-link">
            <div className="item-card">
                <div className='itemcard-image-container'>
                    <img src={`${props.apiUrl}/api/images/thumbnail/${props.images[0]}`}></img>
                </div>
                <div className="description">
                    <h3>{props.title ?? 'No title'}</h3>
                    {/* <p>{props.description.slice(0, 40)}</p> */}
                    <p id="itemprice">${props.price}</p>
                    <p id="itemloc">{props.city ?? 'N'}, {props.state ?? '/A'}</p>
                </div>
            </div>
        </Link>
    );
}

export default ItemCard;