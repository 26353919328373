import React, {useEffect, useState} from 'react';

import Header from './MarketHeader';
import Sidebar from './Sidebar';
import Shop from './Shop';
import './Marketplace.css';
import SearchBar from "../SearchBar/SearchBar";
import Footer from "../Footer/footer";


function Marketplace (props) {
    const [selectedCategory, setSelectedCategory] = useState('all categories');
    const [isSidebarActive, setIsSidebarActive] = useState(false);
    const [location, setL] = useState(-1);
    const [price, setP] = useState(-1);
    const [whereResults, setWhereResults] = useState(null);
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [filteredLocation, setFL] = useState('');
    useEffect(() => {
        async function setter() {
            setFL(await cityStateToCoords(city, state));
        }
        setter();
        console.log(filteredLocation);
    }, [state, city]);
    useEffect(() => {
        props.getCurrentLocation().then(position => {
            props.setCL(position);
        })
    }, []);
    useEffect(() => {
        props.getWhereAbouts(setWhereResults);
    }, [props.currentLocation]);
    useEffect(() => {
        if (whereResults) {
            setState(whereResults.split(',')[1].trim().split(' ')[0]);
            setCity(whereResults.split(',')[0].split(' ').slice(1).join(' '));
        }
    }, [whereResults]);
  const toggleSidebar = () => {
    setIsSidebarActive(!isSidebarActive);
  };
    const cityStateToCoords = async (city, state) => {
        try {
            const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${city}%20${state}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            const coords = data.results[0]?.geometry.location;
            return coords;
        } catch (error) {
            console.error('Error:', error);
            return {};
        }
    };

    const userName = localStorage.getItem('uName');

    const newProps = {
        ...props,
        userName: userName,
        isSidebarActive: isSidebarActive,
        toggleSidebar: toggleSidebar,
        selectedCategory: selectedCategory,
        setSelectedCategory: setSelectedCategory,
        setP,
        setL,
        location,
        price,
        city,
        state,
        setCity,
        setState,
        cityStateToCoords,
        filteredLocation,
    };
  return (
    <div className="marketplace">
        <Sidebar {...newProps}/>
        <div className={'market-container'}>
            <Header {...newProps}/>
            <Shop {...newProps}/>
        </div>
      {/* Other components will be added here */}
    </div>
  );
} 

export default Marketplace;
