import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './MarketListing.css';
import Header from "../Header/header";
import Footer from "../Footer/footer";
import ListingInfo from './ListingInfo';
import './ListingInfo.css';
import EditMarketListing from './EditMarketListing';
import { useNavigate } from "react-router-dom";
import MessageSeller from "./MessageSeller";
import placeholder from '../assets/images/default-avatar.png';

const MarketListing = (props) => {
    const navigate = useNavigate();
    const { uriParams } = useParams();
    const [item, setItem] = useState({});
    const [ownerItems, setOI] = useState([]);
    const [editingItem, setEI] = useState(false);
    const [messagingSeller, setMS] = useState(false);
    const _id = uriParams ? JSON.parse(decodeURIComponent(uriParams)) : {};
    const [refreshKey, setRefreshKey] = useState(0);
    const [currentSlide, setCurrentSlide] = useState(0); // To track the current slide index
    const [preview, setPreview] = useState(null);

    useEffect(() => {
        props.handleAPI(`listings/${_id}`, null, 'GET', {})
            .then(data => {
                setItem(data);
                if (data.images && data.images.length > 0) {
                    // Set the initial background image
                    document.querySelector('#slidecont').style.setProperty('--bg-image', `url(${props.apiUrl}/api/images/${data.images[currentSlide]})`);
                }
            })
            .catch(error => console.error("Failed to fetch item:", error));

        props.handleAPI(`listings/getOwnerItems/${localStorage.getItem('sessionId')}`, null, 'GET', {})
            .then(data => setOI(data.map(id => id.toString())))
            .catch(() => console.log("Failure to get items"));
    }, [refreshKey, editingItem]);

    useEffect(() => {
        if (item.owner) {
            fetch(`${props.apiUrl}/api/auth/${item.owner}`, {
                method: 'GET'
            }).then(async res => {
                if (res.ok) {
                    const j = await res.json();
                    setPreview(j.id ? `${props.apiUrl}/api/images/${j.id}` : null);
                } else {
                    console.log('Failed to fetch owner data');
                    setPreview(null);
                }
            })
            .catch(error => console.error("Failed to fetch owner data:", error));
        }
    }, [item.owner, props.apiUrl]);

    useEffect(() => {
        if (item.images && item.images.length > 0) {
            document.querySelector('#slidecont').style.setProperty('--bg-image', `url(${props.apiUrl}/api/images/${item.images[currentSlide]})`);
        }
    }, [currentSlide, item.images, props.apiUrl]);

    const goBack = () => {
        localStorage.setItem('returnRoute', '/');
        navigate('/');
    };

    const deleteListing = () => {
        props.handleAPI(`listings/${_id}/${localStorage.getItem('sessionId')}`, null, 'DELETE', {})
            .then(r => {
                if (r.success) goBack();
                else console.log(r);
            });
    };

    const editListing = () => setEI(true);

    const messageSeller = () => {
        if (props.sessionId) {
            setMS(true);
        } else {
            navigate('/Login');
        }
    };

    const nextSlide = () => {
        if (item.images && item.images.length > 0) {
            setCurrentSlide((currentSlide + 1) % item.images.length);
        }
    };

    const prevSlide = () => {
        if (item.images && item.images.length > 0) {
            setCurrentSlide((currentSlide - 1 + item.images.length) % item.images.length);
        }
    };

    return (
        <>
            <Header {...props} />

            <main className={`listing-page ${editingItem ? 'blur' : ''}`}>
                <div className={'ml_cont'}>
                    <div className="left_side">
                        <div id="slidecont" className="image-slider">
                            {item.images && item.images.length > 0 ? (
                                <>
                                    <img
                                        src={`${props.apiUrl}/api/images/${item.images[currentSlide]}`}
                                        alt={`Slide ${currentSlide}`}
                                        className="slide-image"
                                    />
                                    <div className="slider-controls">
                                        <button onClick={prevSlide}>&lt;</button>
                                        <button onClick={nextSlide}>&gt;</button>
                                    </div>
                                </>
                            ) : (
                                <p>No images available</p>
                            )}
                        </div>
                        <div id="user_box" onClick={() => navigate(`/view/${item.owner}`)}>
                            <div id="user_box_imgbox">
                                <img src={preview ? preview : placeholder} alt={'m_pfp'} />
                            </div>
                            <div id="user_box_infobox">
                                <p>{item.user}</p>
                            </div>
                        </div>
                    </div>
                    <div className="right_side">
                        <div id='listinginfocont'>
                            <ListingInfo {...props} refreshKey={refreshKey} />
                        </div>
                    </div>
                </div>
                <div className="buttondiv">
                    <div className='leftbuttons'>
                        <button id='back' onClick={goBack}>Back</button>
                    </div>
                    <div className='rightbuttons'>
                        {!ownerItems.includes(item._id) && !messagingSeller && <button id={'delete'} onClick={messageSeller}>Make an offer</button>}
                        {ownerItems.includes(item._id) && <button id={'delete'} onClick={deleteListing}>Delete</button>}
                        {ownerItems.includes(item._id) && <button id={'edit'} onClick={editListing}>Edit</button>}
                    </div>
                </div>
            </main>

            {editingItem && !messagingSeller && (
                <div className={`EMLoverlay ${editingItem ? 'active' : ''}`}>
                    <EditMarketListing {...props} item={item} setRK={setRefreshKey} setEI={setEI} isVisible={editingItem} />
                </div>
            )}
            {props.sessionId && !editingItem && messagingSeller && (
                <MessageSeller {...props} setMessage={setMS} owner={item.owner} passedMSG={`Hey! I'm interested in ${item.title}. Is it still available?`} />
            )}
        </>
    );
}

export default MarketListing;
