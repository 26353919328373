import './Verify.css'
import {useEffect, useState, useRef } from "react";
import {useNavigate, useParams} from "react-router-dom";
import Header from '../Header/header';
import Footer from '../Footer/footer';
import email_icon from '../assets/images/email_icon.png'

const Verify = (props) => {
    const [msg, setM] = useState('Loading...');
    const [stat, setStatus] = useState(false);
    const {token} = useParams();
    const navigate = useNavigate();
    const hasRun = useRef(false);
    const [mounted, setMounted] = useState(false)
    const [error, setError] = useState(false);

    useEffect(() => {
        setMounted(true);
    }, []);

    if (!hasRun.current && mounted) {
        hasRun.current = true;
        console.log('here');
        console.log(
        fetch(`${props.apiUrl}/api/auth/verify/${token}`, {
            method: 'GET'
        }).then((res) => {
            if (res.ok) {
                setM('Account verified!');
                setStatus(true);
                console.log(msg);
            } else {
                setM('Failure to verify');
                console.log('jobe');
            }
            console.log(res);
        }).catch(error => {
            setM('Failure to verify')
        }));
    }
    const handleClick = () => {
            navigate('/Login')
    }

    const handleHeaderClick = () => {
        if (msg === 'Account verified!') {
            navigate('/Login');
        }
    }

    useEffect(() => {
        if (msg === 'Failure to verify') {
            setError(true);
        }
    }, [msg])
    return (
        <>
            <Header {...props}/>
            <div className={'loggerin'}>
                <div className={'signlogbox'}>
                    <img  id='email' src={email_icon}></img>
                    <h1 id='dv-header' onClick={handleHeaderClick}>{msg}</h1>
                    <button id='rtli' onClick={handleClick}>Return to log in</button>
                    {error && <p className='err'>An error occurred. Please return to log in and try again.</p>}
        
                </div>
            </div>
        </>   
    );
}
export default Verify