import {useParams} from "react-router-dom";
import {useState} from "react";

const ResetPassword = (props) => {
    const {token} = useParams();
    const [password, setP] = useState('');
    const [repassword, setREP] = useState('');
    const [done, setDone] = useState(false);
    const [passwordError, setPasswordError] = useState('');
    const handlePasswordChange = (event) => {
        setP(event.target.value);
    };

    const handlePasswordConfirmChange = (event) => {
        setREP(event.target.value);
    };
    const resetPass = async () => {
        if (password === repassword) {
            try {
                const response = await fetch(`${props.apiUrl}/api/auth/reset/pass/${token}/${password}`);
                const data = await response.json();
                // console.log('data: ', data);
                // console.log(token, password)
                if (data.error) {
                    if (data.error === 'Password empty') {
                        setPasswordError('passwordEmpty')
                    } else if (data.error === 'Password invalid') {
                        setPasswordError('passwordInvalid');
                    } else if (data.error === 'Token not found') {
                        setPasswordError('tokenNotFound');
                    } else if (data.error === 'Token has expired') {
                        setPasswordError('tokenExpired');
                    }
                } else {
                    setDone(true);
                    setPasswordError('');
                }
            } catch (error) {
                console.error('Error:', error);
            }
        } else {
            setPasswordError('passDontMatch');
        }
    }

    return (
        <div className="loggerin">
            <div className="signlogbox">
                    {!done && <input
                        type='password'
                        placeholder='Enter new password'
                        onChange={handlePasswordChange}
                    />}
                    {!done && <input
                        type='password'
                        placeholder='Confirm new password'
                        onChange={handlePasswordConfirmChange}
                    />}
                    {!done && <button onClick={() => resetPass()}>
                        Reset Password
                    </button>}
                    {passwordError === 'passDontMatch' && <p className="err">Passwords do not match</p>}
                    {passwordError === 'passwordEmpty' && <p className='err'>Password cannot be empty.</p>}
                    {passwordError === 'passwordInvalid' && <p className='err'>Password must be 8-30 characters and include both letters and numbers.</p>}
                    {passwordError === 'tokenNotFound' && <p className="err">This reset password link has already been used and cannot be used again.</p>}
                    {passwordError === 'tokenExpired' && <p className="err">This link has expired. Please get a new reset password link.</p>}
                    {done && <p>Success! <a href={'/Login'}>Click to log in</a></p>}
            </div>
        </div>
    );
}

export default ResetPassword;
