import './Signup.css'
import Header from "../Header/header";
import Footer from "../Footer/footer";
import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import { JSEncrypt } from "jsencrypt";

function Signup(props) {
    // console.log(localStorage.getItem('returnRoute'));
    const navigate = useNavigate();
    const [user, setUser] = useState('');
    const [email, setEmail] = useState('');
    const [pass, setPass] = useState('');
    const [loading, setLoading] = useState(false);
    const [signupError, setSignupError] = useState('');

    const huc = (event) => {
        setUser(event.target.value);
    };
    const hec = (event) => {
        setEmail(event.target.value);
    };
    const hpc = (event) => {
        setPass(event.target.value);
    };
    const handleClick = () => { // click button... => 
        setLoading(true); // this should trigger the useEffect that generates keypair to run
        handleSubmit();
    }

    const handleSubmit = async () => {    
                try {
                    const creds ={
                        username: user,
                        password: pass,
                        email: email,
                    };
                    console.log('creds: ', creds);
                    const response = await fetch(`${props.apiUrl}/api/auth/signup`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(creds)
                    });
                    
                    const data = await response.json();
            
                    if (data.error) {
                        if (data.error === 'User already exists') {
                            setSignupError('userAlreadyExists')
                        } else if (data.error === 'Email already registered') {
                            setSignupError('emailAlreadyRegistered');
                        } else if (data.error === 'Username invalid') {
                            setSignupError('usernameInvalid');
                        } else if (data.error === 'Email invalid') {
                            setSignupError('emailInvalid');
                        } else if (data.error === 'Password invalid') {
                            setSignupError('passwordInvalid');
                        } else if (data.error === 'Username empty') {
                            setSignupError('usernameEmpty');
                        } else if (data.error === 'Password empty') {
                            setSignupError('passwordEmpty');
                        } else if (data.error === 'Email empty') {
                            setSignupError('emailEmpty');
                        }
                    } else {
                        navigate(`/verifyit/${creds.email}`);
                        localStorage.setItem('emailDisplay', `${creds.email}`)
                    }
                    setLoading(false);
                } catch (error) {
                        console.error('Error during signup:', error);
                }
        };

    // adding this so people can press enter to submit.
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleClick();
        }
    };


    return (
        <>
            <Header {...props}/>
            <div className={'loggerin'}>
            <div className={'signlogbox'}>
                <p>We are glad to have you!</p>
                <input type={'text'} value={user} onChange={huc} onKeyDown={handleKeyDown} placeholder="Username" />
                <input type={'text'} value={email} onChange={hec} onKeyDown={handleKeyDown} placeholder="Email" />
                <input type={'password'} value={pass} onChange={hpc} onKeyDown={handleKeyDown} placeholder="Password" />
                {signupError === 'userAlreadyExists' && <p className='err'>Username already taken, please try another.</p>}
                {signupError === 'emailAlreadyRegistered' && <p className='err'>An account has already been registered with this email.</p>}
                {signupError === 'usernameEmpty' && <p className='err'>Username cannot be empty.</p>}
                {signupError === 'passwordEmpty' && <p className='err'>Password cannot be empty.</p>}
                {signupError === 'emailEmpty' && <p className='err'>Email cannot be empty.</p>}
                {signupError === 'usernameInvalid' && <p className='err'>Username must be 3-18 characters and may only include letters and numbers.</p>}
                {signupError === 'passwordInvalid' && <p className='err'>Password must be 8-30 characters and include both letters and numbers.</p>}
                {signupError === 'emailInvalid' && <p className='err'>Please enter a valid email address (e.g., example@example.com).</p>}
                {!loading && <button onClick={handleClick}>Sign Up</button>}
                {loading && <p>Please wait...</p>}
            </div>
            </div>
        </>
    );
}

export default Signup;
