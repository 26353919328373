import './Login.css'
import React, {useEffect, useState} from "react";
import Header from "../Header/header";
import Footer from "../Footer/footer";
import {useNavigate, useLocation} from "react-router-dom";
import {use} from "bcrypt/promises";
const Login = (props) => {
    // console.log(localStorage.getItem('returnRoute'));
    const [user, setUser] = useState('');
    const [pass, setPass] = useState('');
    const [wp, setWP] = useState(false);
    const [resetting, setResetting] = useState(false);
    const [loginError, setLoginError] = useState('');

    const navigate = useNavigate();
    const returnRoute = localStorage.getItem('returnRoute');
    const credentials = {
        email: user,
        password: pass,
    };
    const huc = (event) => {
        setUser(event.target.value);
        credentials.email = user;
    };
    const hpc = (event) => {
        setPass(event.target.value);
        credentials.password = pass;
    };
    const resetPass = () => {
        setResetting(true);
    }
    const process = async () => {
        if (!resetting) {
            fetch(`${props.apiUrl}/api/auth/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(credentials)
            })
                .then(response => {
                    if (!response.ok) {
                        setWP(true);
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then(data => {
                    console.log(data);
                    if (data.type && data.type === '1') {
                        navigate(`/verifyit/${credentials.email}`);
                        localStorage.setItem('emailDisplay', `${credentials.email}`)
                        return;
                    }
                    props.handleLogin(data);
                    if (returnRoute === null) {
                        navigate('/');
                    } else {
                        if (returnRoute) {
                            navigate(returnRoute);
                        } else {
                            navigate('/');
                        }
                    }
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        }
        if (resetting) {
            try {
                const response = await fetch(`${props.apiUrl}/api/auth/reset-password`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({email: credentials.email})
                });
    
                const data = await response.json();
    
                if (data.error) {
                    if (data.error === 'Email empty') {
                        setLoginError('emailEmpty');
                    } else if (data.error === 'Email not registered') {
                        setLoginError('emailNotRegistered');
                    }
                } else {
                    navigate('/');
                }
            } catch (error) {
                console.error('Error:', error);
            }
           
        }
    };

    // adding this so people can press enter to submit.
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            process();
        }
    };

    const backToLogin = () => {
        setResetting(false);
        setWP(false);
        setLoginError('');
    }

    return (
        <>
            <Header {...props}/>
            <div className={'loggerin'}>
            <div className={'signlogbox'}>
                <p1>Glad to have you back!</p1>
                <input type={'text'} value={user} onChange={huc} onKeyDown={handleKeyDown} placeholder="Email" />
                {!resetting && <input type={'password'} value={pass} onChange={hpc} onKeyDown={handleKeyDown} placeholder="Password" />}
                {loginError === 'emailEmpty' && <p className='err'>Email cannot be empty.</p>}
                {loginError === 'emailNotRegistered' && <p className='err'>No account exists with that email address.</p>}
                <button onClick={process}>Proceed</button>
                {!resetting && wp && <p onClick={resetPass} >Wrong Password or Username, <a>Click here to reset password!</a></p>}
                {resetting && <p onClick={backToLogin} >Remembered your password? <a>Click here to go back!</a></p>}
                <p>Don't have an account?<a href={'/Signup'}> Signup!</a></p>
            </div>
            </div>
        </>
    );
};
export default Login