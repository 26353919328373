import './EditProfile.css';
import Header from "../Header/header";
import Footer from "../Footer/footer";
import {useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import placeholder from '../assets/images/default-avatar.png'


const EditProfile = (props) => {
    const navigate = useNavigate();
    const [statement, setStatement] = useState('');
    const [formData, setFormData] = useState('');
    const [doneApplying, setDoneApplying] = useState(false)
    const [profilePic, setProfilePic] = useState(null);
    const [preview, setPreview] = useState(null);
    const [headerRefresh, setHeaderRefresh] = useState(false);
    const [showButtons, setShowButtons] = useState(false);
    const [origPFP, setOrigPFP] = useState(null);


    useEffect(() => {
        console.log(props.ownerId);
        fetch(`${props.apiUrl}/api/auth/${localStorage.getItem('ownerId')}`, {
            method: 'GET'
        }).then(async res => {
            if (res.success) {
                console.log(res, 'failure');
            }
            const j = await res.json();

            if (j.id !== '') {
                setPreview(`${props.apiUrl}/api/images/${j.id}`);
                setOrigPFP(`${props.apiUrl}/api/images/${j.id}`);
            }
            setFormData({
                username: j.user,
                email: j.email,
                description: j.description,
                state: j.location.state,
                city: j.location.city,
                sessionId: localStorage.getItem('sessionId')
            })
            //  console.log(preview)
        })
        localStorage.setItem('returnRoute', `/EditProfile`);
    }, []);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleLogoutAndVerifyEmail = () => {
        props.handleLogout();
        navigate(`/verifyit/${formData.email}`);
    }

    const handleSubmit = async (event) => {
        // confirm user wants to change email, if email is different
        if (formData.email !== props.ownerEmail) {
            if (!window.confirm('Changing your email will log you out and require you to re-verify your account. You will not be able to log back in until you verify the new email address. Are you sure you want to do this?')) {
                event.preventDefault();
                return;
            }
        }
        setStatement('processing...');
        event.preventDefault();
        const response = await fetch(`${props.apiUrl}/api/auth/`, { // verified: false gets set in here if changing email
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData)
        });
        const data = await response.json();
        if (data.error) {
            if (data.error === 'User already exists') {
                setStatement('userAlreadyExists')
            } else if (data.error === 'Email already registered') {
                setStatement('emailAlreadyRegistered');
            } else if (data.error === 'Username invalid') {
                setStatement('usernameInvalid');
            } else if (data.error === 'Email invalid') {
                setStatement('emailInvalid');
            } else if (data.error === 'Password invalid') {
                setStatement('passwordInvalid');
            } else if (data.error === 'Username empty') {
                setStatement('usernameEmpty');
            } else if (data.error === 'Password empty') {
                setStatement('passwordEmpty');
            } else if (data.error === 'Email empty') {
                setStatement('emailEmpty');
            }
        } else {
            setStatement('Success');
            localStorage.setItem('uName', formData.username);
            localStorage.setItem('ownerEmail', formData.email);
            localStorage.setItem('ownerD', formData.description);
            localStorage.setItem('ownerS', formData.state);
            localStorage.setItem('ownerC', formData.city);
            props.setUsername(formData.username);
            props.setEmail(formData.email);
            props.setDescription(formData.description);
            props.setOwnerState(formData.state);
            props.setOwnerCity(formData.city);
            if (formData.email !== props.ownerEmail) { // if updating email,
                handleLogoutAndVerifyEmail();
            }
        }
    
        // props.handleAPI('auth', formData, 'PUT', {})
        //     .then(r => {
        //         console.log('r: ', r);
        //         if (r.error) {
        //             if (r.error === 'User already exists') {
        //                 setStatement('userAlreadyExists')
        //             } else if (r.error === 'Email already registered') {
        //                 setStatement('emailAlreadyRegistered');
        //             } else if (r.error === 'Username invalid') {
        //                 setStatement('usernameInvalid');
        //             } else if (r.error === 'Email invalid') {
        //                 setStatement('emailInvalid');
        //             } else if (r.error === 'Password invalid') {
        //                 setStatement('passwordInvalid');
        //             } else if (r.error === 'Username empty') {
        //                 setStatement('usernameEmpty');
        //             } else if (r.error === 'Password empty') {
        //                 setStatement('passwordEmpty');
        //             } else if (r.error === 'Email empty') {
        //                 setStatement('emailEmpty');
        //             }
        //             return;
        //         }

        //         if (r.success) {
        //             setStatement('Success');
        //             localStorage.setItem('uName', formData.username);
        //             localStorage.setItem('ownerEmail', formData.email);
        //             localStorage.setItem('ownerD', formData.description);
        //             localStorage.setItem('ownerS', formData.state);
        //             localStorage.setItem('ownerC', formData.city);
        //             props.setUsername(formData.username);
        //             props.setEmail(formData.email);
        //             props.setDescription(formData.description);
        //             props.setOwnerState(formData.state);
        //             props.setOwnerCity(formData.city);
        //         } else {
        //             setStatement('Failure: ' + JSON.stringify(r));
        //         }
        //     });
    };

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setProfilePic(file);
            setDoneApplying(false);
            cropImageToSquare(file);
            setShowButtons(true);
        }
    };

    const cropImageToSquare = (file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const img = new Image();
            img.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
                const squareSize = 200; 
                canvas.width = squareSize;
                canvas.height = squareSize;
                
                const minSide = Math.min(img.width, img.height);
                const startX = (img.width - minSide) / 2;
                const startY = (img.height - minSide) / 2;

                ctx.drawImage(img, startX, startY, minSide, minSide, 0, 0, squareSize, squareSize);
                const dataUrl = canvas.toDataURL('image/jpeg'); 
                setPreview(dataUrl);
            };
            img.src = e.target.result;
        };
        reader.readAsDataURL(file);
    };

    const handleCancel = () => {
        setProfilePic(null);
        setShowButtons(false);
        setPreview(origPFP);
    };

    const handleApply = () => {
        const formData = new FormData();
        formData.append('image', profilePic);
        formData.append('name', 'Some name');
        formData.append('sessionId', props.sessionId);
        formData.append('profile', true);
        fetch(`${props.apiUrl}/api/images`, {
            method: 'POST',
            body: formData
        }).then(async res => {
            if (!res.ok) {
                throw new Error('Failed to upload image');
            }
            const j = await res.json()
            // console.log(j.id);
            setProfilePic(`/images/${j.id}`)
            setDoneApplying(true);
        })
        setHeaderRefresh(prev => !prev);
    };

    return (
        <>
            <Header {...props} key={headerRefresh} />
            <div className={'big_wrap'}>
                <div className="profile_picture_section">
                    <input id="fileInput" type="file" onChange={handleImageChange} />
                    
                    <label id="pfp_label" for="fileInput" class="profile_label">
                        <img src={preview ? preview : placeholder} alt="Profile Preview" className="profile_preview" id="profilePreview" />
                        <div class="pfp_overlay">Change Photo</div>
                    </label>
                </div>
                <div className="image_buttons">
                        {showButtons && !doneApplying && <button onClick={handleApply}>Apply</button>}
                        {showButtons && !doneApplying && <button onClick={handleCancel}>Cancel</button>}
                        {doneApplying && <p>Photo updated successfully!</p>}    
                </div>
                <form onSubmit={handleSubmit}>
                    <div className="edit_profile_wrap">
                            <div className='ep_section'>
                                <h2 className='ep_header'>Account</h2>
                                <div className='inputarea'>
                                    <label className='inputlabel'>Username:</label>
                                    <input
                                        name="username"
                                        type="text"
                                        value={formData.username}
                                        onChange={handleChange}
                                        placeholder="username"
                                        className='inputbox'
                                    />
                                </div>
                                <div className='linediv'>
                                    <hr className='greyline'></hr>
                                </div>

                                <div className='inputarea'>
                                    <label className='inputlabel'>Email:</label>
                                    <input
                                        name="email"
                                        type="text"
                                        value={formData.email}
                                        onChange={handleChange}
                                        placeholder="email"
                                        className='inputbox'
                                    />
                                </div>
                                <div className='linediv'>
                                    <hr className='greyline'></hr>
                                </div>

                            </div>
                                
                            <div className='ep_section'>
                            <h2 className='ep_header'>Profile</h2>
                                <div className='inputarea'>
                                    <label className='inputlabel'>Description:</label>
                                    <input
                                        name={"description"}
                                        type={"text"}
                                        value={formData.description}
                                        onChange={handleChange}
                                        placeholder={"description"}
                                        className='inputbox'
                                    />
                                </div>
                                <div className='linediv'>
                                    <hr className='greyline'></hr>
                                </div>

                                <div className='inputarea'>
                                    <label className='inputlabel'>Location:</label>
                                    <div className='halfbox'>
                                        <input
                                            name={"city"}
                                            type={"text"}
                                            value={formData.city}
                                            onChange={handleChange}
                                            placeholder={"city"}
                                            className='inputboxhalf'
                                        />
                                        <input
                                            name={"state"}
                                            type={"text"}
                                            value={formData.state}
                                            onChange={handleChange}
                                            placeholder={"state"}
                                            className='inputboxhalf'
                                        />
                                    </div>

                                </div>
                                <div className='linediv'>
                                    <hr className='greyline'></hr>
                                </div>
                            </div>
                    </div>
                    <div id='btndiv'>
                                <button id='subbtn' type="submit">Make Changes</button>
                    </div>
                </form>

                <div id='statement_box'>
                    {statement === 'Success' && <p>Updated successfully! <a href={`/view/${props.ownerId}`}>Click to view profile.</a> </p>}
                    {statement === 'userAlreadyExists' && <p className='err'>Username already taken, please try another.</p>}
                    {statement === 'emailAlreadyRegistered' && <p className='err'>An account has already been registered with this email.</p>}
                    {statement === 'usernameEmpty' && <p className='err'>Username cannot be empty.</p>}
                    {statement === 'passwordEmpty' && <p className='err'>Password cannot be empty.</p>}
                    {statement === 'emailEmpty' && <p className='err'>Email cannot be empty.</p>}
                    {statement === 'usernameInvalid' && <p className='err'>Username must be 3-18 characters and may only include letters and numbers.</p>}
                    {statement === 'passwordInvalid' && <p className='err'>Password must be 8-30 characters and include both letters and numbers.</p>}
                    {statement === 'emailInvalid' && <p className='err'>Please enter a valid email address (e.g., example@example.com).</p>}
                </div>
            </div>
        </>
    );
};

export default EditProfile;