import React, {useEffect, useState} from 'react';
import './MarketHeader.css';
import { Link } from 'react-router-dom';
import placeholder from '../assets/images/default-avatar.png'
import ProfileDropdown from './ProfileDropdown';


const MarketHeader = (props) => {

  const[dropDown, setDropDown] = useState(false);
  const[peefpee, setPFP] = useState(null);

  useEffect(() => {
      if (props.ownerId)
        fetch(`${props.apiUrl}/api/auth/${props.ownerId}`, {
            method: 'GET'
        }).then(async res => {
            if (res.success) {
                console.log(res, 'failure');
            }
            const j = await res.json();
            // console.log(j.id)
            if (j.id === '' || j.id === undefined) {
              setPFP(null);
          } else {
              setPFP(`${props.apiUrl}/api/images/${j.id}`);
          }
        })
  }, [props.ownerId]);
  const handleSidebarClick = () => {
    props.toggleSidebar();
    const sbButton = document.getElementById('sb-button');
    if (sbButton.textContent === '→') {
      sbButton.textContent = '←';
    }
    else {
      sbButton.textContent = '→';
    }
  }

  const toggleDropDown = (event) => {
    setDropDown(!dropDown)
  }


  return (
    <header className={`marketHeader ${props.isSidebarActive ? 'active' : ''}`}>
        <button id='sb-button' className={'header-button'} onClick={handleSidebarClick}>→</button>
        <div id='mh-right'>
            <Link onClick={() => localStorage.setItem('returnRoute', '')} to="/">Home</Link>
            { /*<Link onClick={() => localStorage.setItem('returnRoute','/About')} to="/About">About</Link> */}
            {props.sessionId.length <= 0 && <Link to="/Login" id='logintxt'>Login</Link>}
            {props.sessionId.length > 0 && <img id='pfp' src={peefpee ? peefpee : placeholder} onClick={toggleDropDown}></img>}
        </div>
        {dropDown && <div id='parent-dd-container'>
                <ProfileDropdown {...props} toggleDropDown = {toggleDropDown} dropDown = {dropDown}/>
          </div>}
    </header>
  );
}

export default MarketHeader;
