import './header.css'
import icon from '../assets/images/joe.png'
import React, {useEffect, useState} from "react";
import { Link } from 'react-router-dom';
import ProfileDropdown from '../Marketplace/ProfileDropdown';
import placeholder from '../assets/images/default-avatar.png'


const Header = (props) => {

    const[dropDown, setDropDown] = useState(false);
    const[peefpee, setPFP] = useState(null);

    useEffect(() => {
        if (props.ownerId)
        fetch(`${props.apiUrl}/api/auth/${props.ownerId}`, {
            method: 'GET'
        }).then(async res => {
            if (res.success) {
                console.log(res, 'failure');
            }
            const j = await res.json();
            // console.log('j', j);
            // console.log('j.id: ', j.id)
            if (j.id === '' || j.id === undefined) {
                setPFP(null);
            } else {
                setPFP(`${props.apiUrl}/api/images/${j.id}`);
            }
        })
    }, [props.ownerId]);

    const toggleDropDown = (event) => {
        setDropDown(!dropDown)
    }
    


    return (
        <div className="header">
            <div className={'left'}>
                <Link onClick={() => localStorage.setItem('returnRoute', '/')} to="/"><img id="img_logo" src={icon} alt={'Logo'}/></Link>
                <Link onClick={() => localStorage.setItem('returnRoute', '/')} to="/"><img id="img_logo" src={icon} alt={'Logo'}/></Link>
            </div>
            <div className={'right'}>
                <Link onClick={() => localStorage.setItem('returnRoute', '/')} to="/">Home</Link>
                {/*<Link onClick={() => localStorage.setItem('returnRoute', '/About')} to="/About">About</Link> */}
                {props.sessionId.length <= 0 && <Link to="/Login" id='logintxt'>Login</Link>}
                {props.sessionId.length > 0 && <img id='pfp2' src={peefpee ? peefpee : placeholder} onClick={toggleDropDown}></img>}
                {dropDown && <div id='parent-dd-container'>
                <ProfileDropdown {...props} toggleDropDown = {toggleDropDown} dropDown = {dropDown}/>
                </div>}
            </div>
            
        </div>
    );
}

export default Header;