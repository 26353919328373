import React, { useEffect, useState } from "react";
import left from '../assets/images/left-arrow.png'
import right from '../assets/images/right-arrow.png'
import './Slides.css'

function Slides(props) {
    const { images, height, width, as, type } = props;
    const [visibleIndex, setVisibleIndex] = useState(0);
    const handlePrev = () => {
        setVisibleIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
    };

    const handleNext = () => {
        setVisibleIndex((prevIndex) => (prevIndex + 1) % images.length);
    };
    useEffect(() => {
        const timer = setInterval(() => {
            handleNext();
        }, 10000);
        return () => clearInterval(timer);
    }, [handleNext]);

    let ah = height / 2;
    let slideStyle = {
        display: 'flex',
        height: height + 'px',
        width: width + 'px',
        transition: 'transform 0.5s ease-in-out', // Apply transition effect
        transform: `translateX(-${visibleIndex * width}px)` // Translate slides horizontally
    };

    return (
        <div className={'slide_cont'} style={{ height: height + 'px', width: width + 'px' }}>
            {type === 1 && <div className={'overlay_image'} style={{ width: width + 'px', height: height + 'px' }}>
                <div style={{ height: as, width: as, position: 'absolute', left: 0, top: ah + 'px' }} onClick={handlePrev}><img id={'left_icon'} src={left} alt="Left" style={{ height: 'auto', width: 'auto', maxWidth: '100%', maxHeight: '100%' }} /></div>
                <div style={{ height: as, width: as, position: 'absolute', left: width + 'px', top: ah + 'px' }} onClick={handleNext}><img id={'right_icon'} src={right} alt="Right" style={{ height: 'auto', width: 'auto', maxWidth: '100%', maxHeight: '100%' }} /></div>
            </div>}
            <div className="slides" style={slideStyle}>
                {images.map((image, index) => (
                    <div key={index} style={{ width: width + 'px' }}>
                        <img
                            src={`${props.apiUrl}/api/images/${props.thumbnail === true ? 'thumbnail/' : ''}${image}`}
                            style={{ height: height + 'px', width: width + 'px' }}
                            alt={`Slide ${index + 1}`}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Slides;
