import React, { useState, useEffect, useRef } from 'react';
import './ProfileDropdown.css'
import {useNavigate} from "react-router-dom";
import { Link } from 'react-router-dom';


const ProfileDropdown = (props) => {
    console.log(props);

    // const dropdownRef = useRef(null);

    const navigate = useNavigate()
    const handleLogoutAndClose = () => {
        props.handleLogout();
        props.toggleDropDown();
        localStorage.removeItem('uName');
        navigate(localStorage.getItem('returnRoute'));
    }


    // WIP
    // const handleClickOutside = (event) => {
    //     if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
    //         props.toggleDropDown();
    //     }
    // };
    // useEffect(() => {
    //     document.addEventListener('mousedown', handleClickOutside);
    //     return () => {
    //         document.removeEventListener('mousedown', handleClickOutside);
    //     };
    // }, []);

    return (
        // <div id='dd-container' ref={dropdownRef}>
        <div id='dd-container'>  
            <span id='triangle'></span>
            <ul className='dd-list'>
                <li><Link to={`/view/${props.ownerId}`}>View profile</Link></li>
                <li><Link to='/EditProfile'>Edit profile</Link></li>
                <li onClick={handleLogoutAndClose}>Log out</li>
            </ul>
        </div>
    )
}

export default ProfileDropdown;