import React, { useState, useEffect } from 'react';
import ItemCard from './ItemCard';
import './Shop.css';
import { useNavigate } from "react-router-dom";
import AddMarketListing from "./AddMarketListing";
import {set} from "mongoose";
import MessageSeller from "./MessageSeller";
import SearchBar from '../SearchBar/SearchBar';

const Shop = (props) => {
    const [items, setItems] = useState([]);
    const [unfilteredItems, setUnfilteredItems] = useState([]);
    const navigate = useNavigate();
    const [addItem, setAddItem] = useState(false);
    const [refreshKey, setRefreshKey] = useState(0);
    const [message, setMessage] = useState(false);
    const [passedFilteredItems, setPassedFilteredItems] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchCheck, setSearchCheck] = useState(0);
    const [notif, setNotif] = useState(0);
   // console.log('SHOP: ', props)
    useEffect(() => {
        fetch(`${props.apiUrl}/api/messages/notif/${props.ownerId}/${props.sessionId}`)
            .then(res => res.json())
            .then(data => {
                if (data.viewed) setNotif(2);
                else setNotif(1);
                console.log(notif);
            })
            .catch(error => {
                setNotif(1);
            });
    }, []);

    useEffect(() => {
        if (!props.selectedCategory) {
            setItems([]);
            setUnfilteredItems([]);
            return;
        }
        fetch(props.selectedCategory !== 'all categories' ? `${props.apiUrl}/api/listings/category/${props.selectedCategory}` : `${props.apiUrl}/api/listings`)
            .then(res => {
                if (!res.ok) {
                    throw new Error(`HTTP error! Status: ${res.status}`);
                }
                return res.json();
            })
            .then(items => {
                setItems(items);
                setUnfilteredItems(items);
            })
            .catch(error => {
                console.error(`Error fetching items for category ${props.selectedCategory}:`, error);
                setItems([]);
                setUnfilteredItems([]);
            });
    }, [props.selectedCategory, refreshKey, props.filteredLocation]);

    const messageStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    };

    const addButtonStyle = {

    };

    const filterBySearch = (searchTerm, list) => {
        const newList = list.filter(item =>
            item.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.city.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.state.toLowerCase().includes(searchTerm.toLowerCase()) ||
            (item.city + ' ' + item.state).toLowerCase().includes(searchTerm.toLowerCase())
        );
        return newList;
    };


    const handleAddItemClick = () => {
        if (props.sessionId === "") {
            navigate('/Login')
        } 
        if (addItem) {
            setAddItem(false);
        } else {
            setAddItem(true);
        }
        if (message) {
            setMessage(false);
        }
    };
    const handleMessageClick = () => {
        if (props.sessionId === "") {
            navigate('/Login')
        }
        if (message) {
            setMessage(false);
        } else {
            setMessage(true);
        }
    };

    const handleBackToListClick = () => {
        setAddItem(false);
    };
   // console.log('LAST: ', items);

    useEffect(() => {
        const filterItems = async () => {
            let filteredItems = unfilteredItems.slice(); // start with ALL items

            if (props.location !== -1) { // if a radius is specified, filter by it
                filteredItems = await Promise.all(filteredItems.map(async item => {
                    const itemCoords = await props.cityStateToCoords(item.city, item.state);
                    if (itemCoords !== undefined) {
                        const result = props.isInDistance(
                            { latitude: props.filteredLocation ? props.filteredLocation.lat : props.currentLocation.latitude,
                                longitude: props.filteredLocation ? props.filteredLocation.lng : props.currentLocation.longitude },
                            { latitude: itemCoords.lat, longitude: itemCoords.lng },
                            props.location
                        );
                        return result ? item : null; // return item if it's in the radius, return null otherwise
                    }
                    return null;
                }));
            }
            filteredItems = filteredItems.filter(item => item !== null); // remove the nulls (items not in the radius)

            // at this point, filteredItems is filtered by location already
            if (props.price !== -1) {
                if (props.price === 25) { // price 0-25
                    filteredItems = filteredItems.filter(item => {
                        return (parseFloat(item.price) <= 25);
                    });
                } else if (props.price === 50) { // price 25-50
                    filteredItems = filteredItems.filter(item => {
                        return ((parseFloat(item.price) >= 25) && (parseFloat(item.price) <= 50));
                    });
                } else if (props.price === 100) { // price 50-100
                    filteredItems = filteredItems.filter(item => {
                        return ((parseFloat(item.price) >= 50) && (parseFloat(item.price) <= 100));
                    });
                } else if (props.price === 101) { // price 100+
                    filteredItems = filteredItems.filter(item => {
                        return (parseFloat(item.price) >= 100);
                    }); 
                }
            }
            filteredItems = filterBySearch(searchTerm, filteredItems); // after considering location and price, now consider search term
            setItems(filteredItems); // now actually update the displayed routes array
            setPassedFilteredItems(filteredItems);
        }
        filterItems();
    }, [unfilteredItems, props.location, props.price, searchCheck]);

    const newerProps = {
        ...props,
        items,
        setItems,
        passedFilteredItems,
        setPassedFilteredItems,
        searchTerm,
        setSearchTerm,
        searchCheck,
        setSearchCheck
    }

    //reload page faster when opening/closing addmarketlisting
    useEffect(() => {
        console.log('debug');
    }, [addItem])
    
    return (
        <>
            <main className={`shop ${addItem ? 'blur' : ''}`}>
                <p id={'main_title'} className={`${props.isSidebarActive ? 'active' : 'non-active'}`}>Joebam.com</p>
                <div className={`centeredSearch ${props.isSidebarActive ? 'active' : ''}`}>
                    <SearchBar {...newerProps} />
                </div>
                <div className={`item-grid ${props.isSidebarActive ? 'active' : ''}`}>
                    {items?.length > 0 ? (
                        items.map(item => (
                            <div id='homepage_itemcard_container'>
                                <ItemCard {...item} apiUrl={props.apiUrl} key={item._id}/>
                            </div>
                        ))
                    ) : (
                        <p style={messageStyle}>There are no items currently in this category!</p>
                    )}
                </div>
            </main>
            {addItem && (
                <div className={`AMLoverlay ${addItem ? 'active' : ''}`}>
                    <div>
                    <AddMarketListing
                        {...props} setAI={setAddItem} setRK = {setRefreshKey} isVisible={addItem}
                        />
                        <button id={"closeshop_button"} onClick={handleAddItemClick}>-</button>
                    </div>
                </div>
            )}
            {message && (
                <MessageSeller
                    {...props} setMessage={setMessage}
                />
            )}
            {!addItem && <button id={"addshop_button"} onClick={handleAddItemClick}>+</button>}
            {!addItem && !message && <button id={"message_button"} className={notif === 2 ? '' : 'red'} onClick={handleMessageClick}>MSG</button>}
            {!addItem && message && <button id={"message_button"} className={notif === 2 ? '' : 'red'} onClick={handleMessageClick}>MSG</button>}
            {!addItem && <button id={"view_more"}>View More</button>}

        </>
    );
}

export default Shop;
