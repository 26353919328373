import React, { useState } from "react";
import leftArrow from '../assets/images/left-arrow.png';
import rightArrow from '../assets/images/right-arrow.png';
import './FilledSlides.css';

function FilledSlides({ images }) {
    const [current, setCurrent] = useState(0);

    const nextSlide = () => {
        setCurrent(current === images.length - 1 ? 0 : current + 1);
    };

    const prevSlide = () => {
        setCurrent(current === 0 ? images.length - 1 : current - 1);
    };

    if (!Array.isArray(images) || images.length <= 0) {
        return null;
    }

    return (
        <div className='slider'>
            <img src={leftArrow} alt='Left Arrow' className='left-arrow' onClick={prevSlide} />
            <img src={rightArrow} alt='Right Arrow' className='right-arrow' onClick={nextSlide} />
            {images.map((image, index) => {
                return (
                    <div className={index === current ? 'slide active' : 'slide'} key={index}>
                        {index === current && (<img src={image} alt='image slide' className='image' />)}
                    </div>
                );
            })}
        </div>
    );
}

export default FilledSlides;
