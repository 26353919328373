import React, { useEffect, useState } from 'react';
import './AddMarketListing.css';
import './EditMarketListing.css';
import Header from "../Header/header";
import Footer from "../Footer/footer";
import { useNavigate } from "react-router-dom";
import MarketHeader from "./MarketHeader";
import {use} from "bcrypt/promises";


const EditMarketListing = (props) => {
    const navigate = useNavigate();
    const [whereResults, setWhereResults] = useState(null);
    const [invalidInput, setInvalidInput] = useState('');
    const [updatingData, setUD] = useState(false);
    const [imagesToDelete, setImagesToDelete] = useState([]);
    const [categories, setCategories] = useState([
        'art supplies',
        'baby products',
        'beauty products',
        'board games',
        'books',
        'candles',
        'cars',
        'cell phone accessories',
        'clothing',
        'craft supplies',
        'electronics',
        'fitness equipment',
        'furniture',
        'gardening supplies',
        'healthcare products',
        'hobby items',
        'home decor',
        'jewelry',
        'kitchen appliances',
        'musical instruments',
        'office supplies',
        'outdoor gear',
        'party supplies',
        'pet supplies',
        'sports equipment',
        'stationery',
        'tools',
        'toys',
        'travel accessories',
        'video games',
        'other'
    ]);
    const [formData, setFormData] = useState({
        title: props.item.title,
        price: props.item.price,
        description: props.item.description,
        images: props.item.images,
        sessionId: props.sessionId,
        category: props.item.category,
        city: props.item.city,
        state: props.item.state,
    });
    // console.log(formData)
    useEffect(() => {
        getWhereAbouts();
    }, []);

    async function getWhereAbouts() {
        try {
            const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${props.currentLocation.latitude},${props.currentLocation.longitude}&location_type=ROOFTOP&result_type=street_address&key=${process.env.REACT_APP_GOOGLE_API_KEY}`);
            if (!response.ok) {
                throw new Error('Bad response: ' + response.status);
            }
            const data = await response.json();
            // console.log('Fetched data:', data);
            setWhereResults(data.results[0]);
        } catch (error) {
            console.error('Error fetching data:', error);
            return {};
        }
    }

    const handleChange = e => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value,
        }));
    };
    const [images, setImages] = useState([]);
    const [totalSlides, setTotalSlides] = useState(props.item.images.length + (images ? images.length : 0));
    const handleImageUpload = (event) => {
        const newImages = Array.from(event.target.files);
        console.log('New Images: ', newImages)
        setImages(newImages);
        setTotalSlides(images.length + 1)
        setHasNoImages(false);
    };

    const [hasNoImages, setHasNoImages] = useState(false);

    const handleSubmit = async e => {
        e.preventDefault();

        // Check for invalid input
        if (isNaN(parseFloat(formData.price)) || parseFloat(formData.price) <= 0) {
            setInvalidInput('price');
            return;
        }
        if (!/[a-zA-Z]/.test(formData.title)) {
            setInvalidInput('title');
            return;
        }

        try {

            var uploadedImageIds = [];
            for (const imgId of props.item.images) { // add old/prev images to the list
                uploadedImageIds.push(imgId);
            }

            // Upload each image and update formData with uploaded image IDs
            for (const image of images) {
                console.log('debug URL.createObjectURL(image): ', URL.createObjectURL(image))
                const formDataImage = new FormData();
                formDataImage.append('image', image);
                formDataImage.append('sessionId', props.sessionId); // Add sessionId to the form data
                formDataImage.append('name', 'Some name'); // Add name to the form data
                const response = await fetch(`${props.apiUrl}/api/images`, {
                    method: 'POST',
                    body: formDataImage // Send formDataImage directly
                });

                if (!response.ok) {
                    throw new Error('Failed to upload image');
                }

                const imageData = await response.json();
                console.log('imageData: ', imageData);
                uploadedImageIds.push(imageData.id); // add newly uploaded images to the list
                console.log('uploadedImageIds: ',uploadedImageIds);
            }

            for (const imageID of imagesToDelete) {
                uploadedImageIds = uploadedImageIds.filter(id => id !== imageID);
            }

            const formDataWithImages = {
                ...formData,
                images: uploadedImageIds
            };
            if (uploadedImageIds.length === 0) {
                console.log('NO IMAGES, CANT SUBMIT');
                setHasNoImages(true);
                return;
            }

            for (const imageID of imagesToDelete) {
                await props.handleAPI(`images/${imageID}/${props.sessionId}`, null, 'DELETE', { sessionId: props.sessionId })
            }

            setFormData(formDataWithImages); 
            setUD(true);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    useEffect(() => {
        if (updatingData) {
            const updateData = async () => {
                try {
                    console.log("new formdata: ", formData)
                    await props.handleAPI(`listings/${props.item._id}`, formData, 'PUT', { sessionId: props.sessionId });
                    props.setEI(false);
                    console.log('setEI false')
                } catch (error) {
                    console.error('Error:', error);
                }
            };

            updateData();
            props.setRK(prevKey => prevKey + 1);
        }
    }, [updatingData]);

    const removeOldImage = (async imageID => {
        console.log('imageID:', imageID);
        console.log('joe');
        setTotalSlides(totalSlides - 1);
        setImagesToDelete(currImagesToDelete => [...currImagesToDelete, imageID]);
        props.item.images = props.item.images.filter(id => id !== imageID);
        setFormData({ ...formData });
        setCurrentSlide(currentSlide - 1);
    });

    const removeNewImage = index => {
        console.log(images.length);
        setTotalSlides(totalSlides - 1)
        setImages(prevImages => {
            const updatedImages = [...prevImages];
            updatedImages.splice(index, 1);
            return updatedImages;
        });
        setFormData({ ...formData });
        setCurrentSlide(currentSlide - 1);
    }
    const [currentSlide, setCurrentSlide] = useState(0);
    useEffect(() => {
        // console.log('rerendering because images.length changed')
    }, [images, currentSlide])

    const cancelHandler = () => {
        props.setEI(false)
        props.setRK(prevKey => prevKey + 1)
    }

    const prevSlide = (event) => {
        event.preventDefault();
        if (currentSlide > 0) setCurrentSlide(currentSlide - 1);
        console.log(currentSlide);
    };

    const nextSlide = (event) => {
        event.preventDefault();
        if (currentSlide < images.length) setCurrentSlide(currentSlide + 1);
        console.log(currentSlide);
    };

    useEffect(() => {
        const track = document.querySelector('.slider-track');
        track.style.transform = `translateX(-${currentSlide * 100}%)`;
    }, [currentSlide]);

    useEffect(() => {
        document.querySelectorAll('.img-container').forEach(container => {
            const imgSrc = container.querySelector('img').src;
            container.style.setProperty('--bg-image', `url(${imgSrc})`);
        });
        if (images.length === 0 && props.item.images.length === 0) {
            setHasNoImages(true);
        }
    }, [props.item.images, images]);

    const [fade, setFade] = useState(false);
    useEffect(() => {
        setFade(true);
    }, [props.isVisible]);

    useEffect(() => {
        if (images.length > 0) {
            setCurrentSlide(images.length - 1); // Slide to the last added image
        }
    }, [images, totalSlides]);

    return (
        <>
            <div className={`edit-listing-container  ${fade ? 'e_active' : ''}`}>
                <div id='ELC-heading'>
                    <h2>Edit Your Market Listing</h2>
                </div>
                <form onSubmit={handleSubmit}>
                    <div id="form-group-wrap">
                        <div className="form-group">
                            <label>Title:</label>
                            <input type="text" name="title" value={formData.title} onChange={handleChange} required />
                            {invalidInput==='title' && <p className='err'>Please enter a valid title.</p>}
                        </div>
                        <div className="form-group">
                            <label>Price:</label>
                            <div className='price-container'>
                                <span className="dollar-sign">$</span>
                                <input type="text" name="price" id="price-input" value={formData.price} onChange={handleChange} required />
                            </div>
                            {invalidInput==='price' && <p className='err'>Please enter a valid price.</p>}
                        </div>
                        <div className="form-group">
                            <label>City:</label>
                            <textarea name="city" value={formData.city} onChange={handleChange} required />
                        </div>
                        <div className="form-group">
                            <label>State:</label>
                            <textarea name="state" value={formData.state} onChange={handleChange} required />
                        </div>
                        <div className="form-group">
                            <label>Description:</label>
                            <textarea name="description" value={formData.description} onChange={handleChange} required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="category">Category:</label>
                            <select name="category" value={formData.category} onChange={handleChange} required>
                                <option value="">Select a category</option>
                                {categories.map(category => (
                                    <option key={category} value={category}>{category}</option>
                                ))}
                            </select>
                        </div>
                        <div className="form-group">
                            <label>Images:</label>
                            <div className='choose-img'>
                                <button type="button" id='filebtn' htmlFor="fileinput" onClick={() => document.getElementById('fileinput').click()}>Choose images to upload</button>
                                <input type="file" id='fileinput' accept="image/*" onChange={handleImageUpload} multiple />
                                <p id='numimgs'>{`${images.length} image(s) selected`}</p>
                            </div>
                            {hasNoImages && <p className='err'>Listing must have at least one image.</p>}
                        </div>
                    </div>
                    
                    <div className='image-slider_e'>
                        {!hasNoImages && <button className='prev-button' onClick={prevSlide}>&lt;</button>}
                        <div className='slider-container'>
                            <div className='slider-track'>
                                {props.item.images.map((imageId, index) => (
                                    <div className='img-and-button-box' key={index}>
                                        <div className='img-container' data-src={`${props.apiUrl}/api/images/${imageId}`}>
                                            <img src={`${props.apiUrl}/api/images/${imageId}`} alt={`Preview ${index + 1}`} />
                                        </div>
                                        <button className='remove' type='button' onClick={() => removeOldImage(imageId)}>Remove</button>
                                    </div>
                                ))}
                                {images && images.length > 0 && (
                                    images.map((image, index) => (
                                        <div className='img-and-button-box' key={index}>
                                            <div className='img-container' data-src={URL.createObjectURL(image)}>
                                                <img src={URL.createObjectURL(image)} alt={`Preview ${index + 1}`} />
                                            </div>
                                            <button className='remove' type='button' onClick={() => removeNewImage(index)}>Remove</button>
                                        </div>
                                    ))
                                )}
                            </div>
                        </div>
                        {!hasNoImages && <button className='next-button' onClick={nextSlide}>&gt;</button>}
                    </div>

                    <div className={'bgroup_E'}>
                        <button type="submit">Edit Listing</button>
                        <button type='button' onClick={cancelHandler}>Cancel</button>
                    </div>
                </form>
            </div>
        </>
    );
};

export default EditMarketListing;
