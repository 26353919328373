import {useEffect, useRef, useState} from "react";
import './MessageSeller.css';
import loadin from '../assets/images/loadin.gif';

const MessageSeller = (props) => {
    const [msg, setMSG] = useState(props.passedMSG || '');
    const [messages, setMessages] = useState([]);
    const [conversations, setConversations] = useState([]);
    const [selectedSender, setSelectedSender] = useState(props.owner || localStorage.getItem('selectedSender'));
    const [reList, setReList] = useState(true);
    const [initialFetchDone, setInitialFetchDone] = useState(false);
    const msgBoxRef = useRef(null);
    useEffect(() => {
        fetchMessages();
    }, [reList, props.ownerId, props.sessionId]);

    const fetchMessages = () => {
        fetch(`${props.apiUrl}/api/messages/${props.ownerId}/${props.sessionId}`)
            .then(res => {
                if (!res.ok) {
                    throw new Error('Network response was not ok');
                }
                return res.json();
            })
            .then(data => {
                setMessages(data);
                setConversations(groupMessagesByConversations(data));
                setReList(false);
                setInitialFetchDone(true);
            })
            .catch(error => {
                console.error('Error fetching messages:', error);
            });
            scrollToBottom();
    };

    const groupMessagesByConversations = (messages) => {
        const convMap = new Map();

        messages.forEach(message => {
            const userId = message.sender.userid === props.ownerId ? message.receiver.userid : message.sender.userid;
            if (!convMap.has(userId)) {
                convMap.set(userId, {
                    user: message.sender.userid === props.ownerId ? message.receiver : message.sender,
                    messages: []
                });
            }
            convMap.get(userId).messages.push(message);
        });

        return Array.from(convMap.values());
    };

    const sendMessage = () => {
        if (selectedSender === props.ownerId) return;
        props.handleAPI('messages', {
            sender: props.ownerId,
            receiver: selectedSender,
            contents: msg,
            type: "Text",
            sessionId: props.sessionId,
        }, 'POST', {}).then(response => {
            console.log(response);
            setReList(true);
        });
        setMSG('');
    };

    const handleInputChange = (event) => {
        setMSG(event.target.value);
    };

    const handleSenderClick = (user) => {
        setSelectedSender(user.userid);
        document.getElementById('conversationHeader').innerHTML = user.username;
        localStorage.setItem('selectedSender', user.userid);
        localStorage.setItem('selectedSenderUser', user.username);
        scrollToBottom();
    };

    const getConversationHeader = () => {
        if (props.owner) {
            return 'New Message';
        } else if (initialFetchDone) {
            return localStorage.getItem('selectedSenderUser');
        } else {
            return '';
        }
    };
    useEffect(() => {
        adjustHeight();
    }, [msg]);

    const adjustHeight = () => {
        if (msgBoxRef.current) {
            msgBoxRef.current.style.height = '75%'; // Reset height to auto to calculate the new height
            const maxHeight = window.innerHeight * 0.20; // 50% of the viewport height
            const scrollHeight = msgBoxRef.current.scrollHeight;
            msgBoxRef.current.style.height = `${Math.min(scrollHeight, maxHeight)}px`; // Set height based on scrollHeight but limit to maxHeight
        }
    };

    function scrollToBottom() {
        const container = document.getElementById('msg_display');
        container.scrollTop = container.scrollHeight;
    }
    return (
        <div className='message_wrap'>
            <div className='leftcolumn'>
                <div className='leftheader'>
                    <p id='chats'>Chats</p>
                </div>
                <div className='user_msg_list'>
                    {reList && <img width='20%' src={loadin} alt='loading' />}
                    <ol id='user_list'>
                        {conversations.length > 0 ? conversations
                            .map(conv => {
                                const isSelected = selectedSender === conv.user.userid;
                                return (
                                    <li
                                        className={isSelected ? 'selected' : ''}
                                        key={conv.user.userid}
                                        onClick={() => handleSenderClick(conv.user)}
                                    >
                                        {conv.user.username.slice(0, 10)}
                                    </li>
                                );
                            }) : <li>No messages</li>
                        }
                    </ol>
                </div>
            </div>
            <div className='rightcolumn'>
                <div className='rightheader'>
                    <p id='conversationHeader'>{getConversationHeader()}</p>
                    <button id='close' onClick={() => props.setMessage(false)}>✕</button>
                </div>
                <div className='msg_user'>
                    <ol id='msg_display'>
                        {selectedSender && messages
                            .filter(message => (
                                (message.sender.userid === selectedSender && message.receiver.userid === props.ownerId) ||
                                (message.receiver.userid === selectedSender && message.sender.userid === props.ownerId)
                            ))
                            .map(message => (
                                <li
                                    key={message.id}
                                    className={message.sender.userid === props.ownerId ? 'right-message' : 'left-message'}
                                >
                                    <div className={message.sender.userid === props.ownerId ? 'the_message receiver' : 'the_message sender'}>
                                        <p>{typeof message.contents === 'object' ? JSON.stringify(message.contents) : message.contents}</p>
                                    </div>
                                </li>
                            ))}
                    </ol>
                </div>
                <div className='rightfooter'>
                    <textarea
                        id='msgBox'
                        ref={msgBoxRef}
                        rows="1"
                        onKeyDown={(e) => { if (e.key === 'Enter') { e.preventDefault(); sendMessage();} }}
                        value={msg}
                        placeholder='Message'
                        onChange={handleInputChange}
                    />
                    <button id='sendbtn' onClick={sendMessage}>Send</button>
                </div>
            </div>
        </div>
    );
};

export default MessageSeller;
